import React from 'react';
import {ThemeProvider, Theme, useTheme, Colors} from '@styles';
import {
  ResponsiveContainer,
  useContainerSize,
  useStickyState,
} from '@components/layout';
import {ContainerProps} from '@components/layout/ResponsiveContainer';
import {StickyMenu, StickyMenuProps} from '@components/Menu';
import Section from './StyledSection';

export type StickySectionProps = StickyMenuProps & {
  theme?: Theme;
  containerProps?: ContainerProps;
  withOffset?: boolean;
  withPadding?: boolean;
};

function StickyContentContainer({
  children,
  withOffset,
  withPadding,
  ...containerProps
}: React.PropsWithChildren<
  ContainerProps & {withOffset?: boolean; withPadding?: boolean}
>): JSX.Element {
  const {wide} = useContainerSize();
  const theme = useTheme();
  const stuck = useStickyState();

  return (
    <ResponsiveContainer {...containerProps}>
      <ThemeProvider
        theme={{
          bg: wide ? theme.bg : Colors.White,
        }}
      >
        <Section noPadding={Boolean(stuck)} aria-label={`Secondary navigation`}>
          {children}
        </Section>
      </ThemeProvider>
    </ResponsiveContainer>
  );
}

export default function StickySection({
  theme,
  containerProps,
  children,
  withOffset,
  withPadding,
  ...stickyProps
}: StickySectionProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <StickyMenu {...stickyProps}>
        <StickyContentContainer
          {...containerProps}
          withOffset={withOffset}
          withPadding={withPadding}
        >
          {children}
        </StickyContentContainer>
      </StickyMenu>
    </ThemeProvider>
  );
}
