import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import {ResponsiveContainer, FontScale} from '@components/layout';
import HeroSectionStandalone from '@components/Hero';
import {
  LatestSection,
  SubNavSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
} from '@components/sections';
import {HeroSectionCallout} from '@components/SectionCallout';
import {OneImageOGProps} from '@components/OrganicImageGrid/OneImageOrganicGridWithContent';
import {TwoImageGridProps} from '@components/OrganicImageGrid/TwoImagesOrganicGridWithContent';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, styled, Colors} from '@styles';
import {detailPagesData} from '@components/Subnavigation/detailPagesData';

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'strategicPartnersLatest'
})`
  background: ${Colors.TintedFogLight};
`;

const enabledRenderBlocks: {[key: string]: (block: any) => JSX.Element} = {
  partneroneimage_block: (block: OneImageOGProps): JSX.Element => {
    return block.imageBlock.image && <OneImageWithContentSection {...block} />;
  },

  partnertwoimages_block: (block: TwoImageGridProps): JSX.Element => {
    return block.imageBlock.image && <TwoImagesWithContentSection {...block} />;
  },
};

function renderBlocks(blocks: {blockType: string}[]): (JSX.Element | null)[] {
  return blocks.map((block: {blockType: string}): JSX.Element | null => {
    return enabledRenderBlocks[block.blockType]
      ? enabledRenderBlocks[block.blockType](block)
      : null;
  });
}

export default function StrategicPartnerships({data}): JSX.Element {
  const {cms} = data;
  const {hero, body, searchDescription, seoTitle, canonicalUrl, parentPage} = cms.page;
  const {header: heroHeader} = getDataFromBlockTypes(hero);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Transparent,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{maxWidth: '600px'}}>
            <HeroSectionStandalone heroSectionType={'short'}>
              <HeroSectionCallout heroProps={[heroHeader]} />
            </HeroSectionStandalone>
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={detailPagesData}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />

          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              bg: Colors.White,
              ctaFg: Colors.Blue,
              ctaBg: Colors.White,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <Fragment>{renderBlocks(body)}</Fragment>
          </ThemeProvider>

          {/* Blog */}
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment StrategicPartnershipsFragment on CMS_SPIndexPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      __typename
      ...headerBlockFragment
      ...pagelinkBlockFragment
    }
    body {
      __typename
      ... on CMS_PartnerOneImageBlock {
        blockType
        pagelinkBlock {
          blockType
          title
          page {
            url
          }
        }
        headerimagebodyBlock {
          body
          headline {
            caption
            image {
              ...cmsFluidImageFragment
            }
          }
        }
        imageBlock {
          caption
          image {
            ...cmsFluidImageFragment
          }
        }
      }
      ... on CMS_PartnerTwoImagesBlock {
        blockType
        pagelinkBlock {
          blockType
          title
          page {
            url
          }
        }
        headerimagebodyBlock {
          body
          headline {
            caption
            image {
              ...cmsFluidImageFragment
            }
          }
        }
        imageBlock {
          caption
          image {
            ...cmsFluidImageFragment
          }
        }
        imageBlock2 {
          caption
          image {
            ...cmsFluidImageFragment
          }
        }
      }
    }
  }

  query StrategicPartnershipsQuery {
    cms {
      page(url: "/home/about/strategic-partnerships/") {
        ...StrategicPartnershipsFragment
      }
    }
  }
`;
