import React from 'react';
import {Colors} from '@styles';
import Subnavigation, {SubnavigationProps} from '@components/Subnavigation';
import StickySection from './StickySection';
import styled from 'styled-components';
import {Sizes} from '@styles';

export type SubNavSectionProps = SubnavigationProps & {
  inline?: boolean;
};

const ResponsiveWrapMobile = styled.span.withConfig({
  componentId: 'subNavSectionResponsiveWrapMobile'
})`
  display: 'none';
  @media (max-width: 767px) {
    display: 'initial';
  }
`;

const ResponsiveWrapDesktop = styled.span.withConfig({
  componentId: 'subNavSectionResponsiveWrapDesktop'
})`
  display: 'initial';
  @media (max-width: 767px) {
    display: 'none';
  }
`;

const WrapContents = ({
  inline,
  children,
  isMobile = false,
}: {
  inline: boolean;
  children: JSX.Element[] | JSX.Element;
  isMobile: boolean;
}) => {
  return (
    <StickySection
      stuck={inline ? false : undefined}
      theme={{
        bg: Colors.White,
        fg: Colors.Charcoal,
        maxPadding: '0',
        minPadding: '0',
        maxWidth: '100%',
      }}
      containerProps={{
        initialContainerSize: {
          narrow: isMobile ? true : false,
          wide: isMobile ? false : true,
        },
      }}
    >
      {children}
    </StickySection>
  );
};

const StickyWrap = ({
  inline,
  children,
  isMobile = false,
}: {
  inline: boolean;
  children: JSX.Element[] | JSX.Element;
  isMobile: boolean;
}) => {
  return (
    <div>
      { isMobile ? (
        <ResponsiveWrapMobile
          isMobile={isMobile}
          arial-label={isMobile ? 'MobileResponsiveWrap' : 'DesktopResponsiveWrap'}
        >
         <WrapContents inline={inline}  children={children}  isMobile={isMobile} />
        </ResponsiveWrapMobile>
      ) : (
        <ResponsiveWrapDesktop
          isMobile={isMobile}
          arial-label={isMobile ? 'MobileResponsiveWrap' : 'DesktopResponsiveWrap'}
        >
          <WrapContents inline={inline}  children={children}  isMobile={isMobile} />
        </ResponsiveWrapDesktop>
      )}
    </div>
  );
};

const DesktopSubnav = (props: SubNavSectionProps) => {
  const isMobile =
    typeof window !== 'undefined' && window.innerWidth <= Sizes.Wide;
  if (typeof window !== 'undefined' && isMobile) return null;
  return (
    <StickyWrap isMobile={false} {...props}>
      <Subnavigation {...props} wide={true} />
    </StickyWrap>
  );
};

const MobileSubnav = (props: SubNavSectionProps) => {
  const isMobile =
    typeof window !== 'undefined' && window.innerWidth <= Sizes.Wide;
  if (typeof window !== 'undefined' && !isMobile) return null;
  return (
    <StickyWrap isMobile={true} {...props}>
      <Subnavigation {...props} wide={false} />
    </StickyWrap>
  );
};

const SubNavSection = (props: SubNavSectionProps): JSX.Element => (
  <>
    <DesktopSubnav {...props} />
    <MobileSubnav {...props} />
  </>
);

export default SubNavSection;
