export const detailPagesData = [
  {
    id: 1,
    name: 'Our Mission',
    slug: 'mission',
  },
  {
    id: 2,
    name: 'Our Platform',
    slug: 'platform',
  },
  {
    id: 3,
    name: 'Our Impact',
    slug: 'impact',
  },
  {
    id: 4,
    name: 'Leadership',
    slug: 'leadership',
  },
];


/*

Our Mission (to link to an updated version of the Manifesto page),
Our Platform (to link to a new page),
Our Impact (to link to an updated version of the Outcomes page), 
Leadership
*/